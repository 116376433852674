import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Link from "../../Link";
import linkArrow from "../../../images/link-arrow.png";
import ReactHtmlParser from "react-html-parser";
import { transformLink } from "../../../helpers";

import iconEye from "../../../images/icon-eye.png";
import iconNose from "../../../images/icon-nose.png";
import iconLips from "../../../images/icon-lips.png";

import sweetBg from "../../../images/sweet-bg.png";
import sweetOver from "../../../images/sweet-over.png";

import bitterBg from "../../../images/bitter-bg.png";
import bitterOver from "../../../images/bitter-over.png";

const styles = (theme) => ({
  beerNotes: {
    "& .see, & .aroma, & .diet-guidelines, & .taste": {
      marginBottom: 24,
      "& p": {
        margin: 0,
        fontSize: 14,
        letterSpacing: "0.024em",
        lineHeight: 2,
        "& strong": {
          fontSize: 16,
          letterSpacing: "0.04em",
          lineHeight: 1.5,
          margin: 0,
          textTransform: "uppercase",
          fontFamily: theme.headerFont,
        },
        "& a.arrow-link": {
          fontSize: 16,
          color: theme.palette.primary.main,
          letterSpacing: "0.06em",
          lineHeight: 1.2,
          display: "inline-block",
          textTransform: "uppercase",
          textDecoration: "none",
          fontFamily: theme.headerFont,
          transition: "color .3s ease-in-out",
          marginTop: 36,
          "&::after": {
            width: 26,
            height: 13,
            display: "inline-block",
            marginLeft: 15,
            backgroundImage: "url(" + linkArrow + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            content: '""',
            transition: "margin-left .3s ease-in-out",
          },
          "&:hover, &:focus": {
            color: theme.palette.primary.dark,
            "&::after": {
              marginLeft: 25,
            },
          },
        },
      },
    },
    "& .see": {
      "& p strong": {
        paddingLeft: 32,
        backgroundSize: 22,
        backgroundImage: "url(" + iconEye + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
        fontSize: 14,
      },
      "& .colours": {
        maxWidth: 200,
        marginTop: 8,
        [theme.breakpoints.up("md")]: {
          maxWidth: "none",
        },
        "& .dot": {
          display: "inline-block",
          width: 28,
          height: 28,
          borderRadius: "50%",
          margin: "0 16px 6px 0",
          position: "relative",
          "&.pale-straw": { backgroundColor: "#FEEF74" },
          "&.pale-gold": { backgroundColor: "#FDC803" },
          "&.pale-amber": { backgroundColor: "#E9A301" },
          "&.medium-amber": { backgroundColor: "#E28300" },
          "&.deep-amber": { backgroundColor: "#B84B14" },
          "&.amber-brown": { backgroundColor: "#882310" },
          "&.ruby-brown": { backgroundColor: "#4C0B0A" },
          "&.black": { backgroundColor: "#32090A", marginRight: 0 },
          "&::before": {
            border: "2px solid",
            borderColor: theme.palette.primary.main,
            position: "absolute",
            top: -4,
            bottom: -4,
            right: -4,
            left: -4,
            borderRadius: "50%",
            display: "block",
          },
        },
        "&.active-pale-straw .dot.pale-straw::before": { content: '""' },
        "&.active-pale-gold .dot.pale-gold::before": { content: '""' },
        "&.active-pale-amber .dot.pale-amber::before": { content: '""' },
        "&.active-medium-amber .dot.medium-amber::before": { content: '""' },
        "&.active-deep-amber .dot.deep-amber::before": { content: '""' },
        "&.active-amber-brown .dot.amber-brown::before": { content: '""' },
        "&.active-ruby-brown .dot.ruby-brown::before": { content: '""' },
        "&.active-black .dot.black::before": { content: '""' },
      },
    },
    "& .taste": {
      "& p strong": {
        paddingLeft: 32,
        backgroundSize: 22,
        backgroundImage: "url(" + iconLips + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
      },
      "& .taste-def": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 8,
        marginBottom: 8,
        "& p": {
          flex: "0 0 70px",
          margin: 0,
          fontSize: 14,
          letterSpacing: "0.024em",
          lineHeight: 2,
        },
        "& > span": {
          display: "block",
          height: 25,
          width: 125,
          backgroundSize: "125px 25px",
          backgroundPosition: "center left",
          "& > span": {
            display: "block",
            height: 25,
            backgroundSize: "125px 25px",
            backgroundPosition: "center left",
          },
        },
        "&.sweet": {
          "& > span": {
            backgroundImage: "url(" + sweetBg + ")",
            "& > span": {
              backgroundImage: "url(" + sweetOver + ")",
            },
          },
        },
        "&.bitter": {
          "& > span": {
            backgroundImage: "url(" + bitterBg + ")",
            "& > span": {
              backgroundImage: "url(" + bitterOver + ")",
            },
          },
        },
      },
    },
    "& .aroma": {
      "& p strong": {
        paddingLeft: 32,
        backgroundSize: 22,
        backgroundImage: "url(" + iconNose + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
      },
    },
    "& hr": {
      border: "none",
      borderTop: "1px solid",
      borderColor: theme.palette.secondary.main,
      margin: "70px 0 30px",
    },
  },
});

class BeerNotes extends Component {
  render() {
    const { classes } = this.props;

    let activeColor = this.props.product.color;
    if (activeColor !== null) {
      activeColor = activeColor.toLowerCase();
      activeColor = activeColor.replace(" ", "-");
    }

    return (
      <div className={classes.beerNotes}>
        <div className="see">
          <p>
            <strong>See</strong>
          </p>
          <div className={`colours active-${activeColor}`}>
            <div className="dot pale-straw" />
            <div className="dot pale-gold" />
            <div className="dot pale-amber" />
            <div className="dot medium-amber" />
            <div className="dot deep-amber" />
            <div className="dot amber-brown" />
            <div className="dot ruby-brown" />
            <div className="dot black" />
          </div>
          <p>{this.props.product.colourDescription}</p>
        </div>
        <div className="aroma">
          <p>
            <strong>Smell</strong>
          </p>
          <p>{this.props.product.aroma}</p>
        </div>
        <div className="taste">
          <p>
            <strong>Taste</strong>
          </p>
          <p>{this.props.product.taste}</p>
          <div className="taste-def sweet">
            <p>Sweet</p>
            <span>
              <span
                style={{ width: this.props.product.sweetness * 20 + "%" }}
              />
            </span>
          </div>
          <div className="taste-def bitter">
            <p>Bitter</p>
            <span>
              <span
                style={{ width: this.props.product.bitterness * 20 + "%" }}
              />
            </span>
          </div>
        </div>
        <hr />
        <div className="diet-guidelines">
          <p>
            <strong>Dietary Guidelines</strong>
          </p>
          {ReactHtmlParser(this.props.product.dietaryGuidelines, {
            transform: transformLink,
          })}
          <p>
            <Link
              className="arrow-link"
              to={
                this.props.product.healthInformationPdf
                  ? this.props.product.healthInformationPdf
                  : "https://rob-cdn.ams3.digitaloceanspaces.com/website/general/Health_and_product_Information_V9.pdf"
              }
            >
              Health Information PDF
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BeerNotes);
