import React, { Component } from "react";
import typeIcon from "../../../images/beer-type-icon.png";
import abvIcon from "../../../images/abv-icon.png";
import typeIconDark from "../../../images/beer-type-icon-dark.png";
import abvIconDark from "../../../images/abv-icon-dark.png";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  productMeta: {
    "& .meta-item": {
      marginBottom: 24,
      fontSize: 12,
      fontStyle: "italic",
      letterSpacing: "0.032em",
      color: theme.palette.common.white,
      fontFamily: theme.bodyFont,
      lineHeight: 1,
      [theme.breakpoints.up("md")]: {
        marginRight: 30,
        float: "left",
        fontSize: 16,
        width: 90,
        textAlign: "center",
      },
      "& span": {
        display: "block",
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: "0.04em",
        textTransform: "uppercase",
        fontSize: 14,
        lineHeight: 1,
        fontFamily: theme.headerFont,
        [theme.breakpoints.up("md")]: {
          fontSize: 18,
          textAlign: "center",
        },
      },
      "& .img": {
        display: "block",
        marginBottom: 5,
        width: 44,
        height: 44,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        [theme.breakpoints.up("md")]: {
          margin: "0 auto 10px",
        },
      },
      "&.type .img": {
        backgroundImage: "url(" + typeIcon + ")",
      },
      "&.abv .img": {
        backgroundImage: "url(" + abvIcon + ")",
      },
    },

    "&.small": {
      display: "flex",
      "& .meta-item": {
        margin: "0 1.2em",
        width: 65,
        fontSize: 12,
        textAlign: "center",
        "& span": {
          fontSize: 12,
          textAlign: "center",
        },
        "& .img": {
          height: 25,
          width: 25,
          margin: "0 auto 10px",
        },
        "&.type .img": {
          backgroundImage: "url(" + typeIconDark + ")",
        },
        "&.abv .img": {
          backgroundImage: "url(" + abvIconDark + ")",
        },
      },
    },

    "&.dark": {
      "& .meta-item": {
        color: theme.palette.common.black,
      },
    },
  },
});

class ProductMeta extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={`product-meta ${classes.productMeta}`}>
        <div className="meta-item type">
          <span className="img" />
          <span>{this.props.beer.beerType}</span>
        </div>
        <div className="meta-item abv">
          <span className="img" />
          <span>{this.props.beer.alcoholVolume}</span>
          Vol.
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProductMeta);
