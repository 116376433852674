import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import { transformLink } from "../../../helpers";

const styles = (theme) => ({
  productDescription: {
    fontSize: 14,
    letterSpacing: "0.024em",
    lineHeight: 2,
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      paddingRight: 60,
      fontSize: 16,
      letterSpacing: "0.032em",
    },
    "& a": {
      color: theme.palette.primary.main,
      "&:hover, &:focus": {
        color: theme.palette.primary.dark,
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      fontSize: 24,
      marginBottom: 40,
      letterSpacing: "0.056em",
      lineHeight: 1.25,
      fontFamily: theme.headerFont,
      [theme.breakpoints.up("md")]: {
        fontSize: 36,
        lineHeight: 1.5,
        letterSpacing: "0.06em",
      },
    },
    "& p": {
      fontSize: 14,
      letterSpacing: "0.024em",
      lineHeight: 2,
      marginBottom: 36,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        letterSpacing: "0.032em",
      },
    },
    "& .tasting-notes-description": {
      "& h3": {
        color: theme.palette.common.black,
        textTransform: "uppercase",
        lineHeight: 1.25,
        fontSize: 24,
        marginBottom: 16,
        letterSpacing: "0.04em",
        "&::before": {
          content: '""',
          width: 32,
          borderTop: "4px solid " + theme.palette.primary.main,
          display: "block",
          marginBottom: 12,
        },
      },
    },
  },
  productDescriptionTheme: {
    ...theme.productDescriptionTheme,
  },
});

class ProductDescription extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div
        className={`${classes.productDescription} ${classes.productDescriptionTheme}`}
      >
        <div className="tasting-notes-description">
          <h3>Tasting Notes</h3>
        </div>
        {ReactHtmlParser(this.props.product.description, {
          transform: transformLink,
        })}
      </div>
    );
  }
}

export default withStyles(styles)(ProductDescription);
