import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid/Grid";

import GoBack from "../components/GoBack";
import ProductMeta from "../components/Shop/ProductMeta";
import ProductImageSlider from "../components/Shop/ProductImageSlider";

import backArrow from "../../src/images/arrow-prev-light.png";
import BeerNotes from "../components/Shop/BeerNotes";
import ProductDescription from "../components/Shop/ProductDescription";
import ProductShare from "../components/Shop/ProductShare";

import sliderArrow from "../images/slider-arrow.png";
import linkArrow from "../images/arrow-next-light.png";

const styles = (theme) => ({
  productBanner: {
    padding: "15px 0 60px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingBottom: 80,
    },
    marginBottom: 80,
    "& .bg": {
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50vw)",
      backgroundColor: theme.palette.secondary.main,
    },
    "& .go-back": {
      backgroundColor: "transparent",
      border: "none",
      color: theme.palette.common.white,
      fontFamily: theme.headerFont,
      fontSize: 14,
      letterSpacing: "0.05em",
      lineHeight: "18px",
      textTransform: "uppercase",
      padding: 0,
      outline: "none !important",
      paddingLeft: 28,
      backgroundImage: "url(" + backArrow + ")",
      backgroundPosition: "center left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      transition: "opacity .3s ease-in-out",
      "&:hover, &:focus": {
        opacity: 0.8,
      },
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 45,
        zIndex: 5,
        fontSize: 16,
      },
    },
    "& h1": {
      fontFamily: theme.headerFont,
      color: theme.palette.common.white,
      fontSize: 24,
      lineHeight: 1,
      letterSpacing: "0.056em",
      margin: "30px 0 10px",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: 32,
        position: "absolute",
        top: 100,
        lineHeight: "1.25",
        width: 280,
      },
    },
    "& .slider-wrap": {
      position: "relative",
      marginBottom: 60,
      "&::before": {
        display: "block",
        width: "100vw",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: theme.palette.common.white,
        height: 1,
        content: '""',
        bottom: 20,
        position: "absolute",
        [theme.breakpoints.up("md")]: {
          width: "100%",
          bottom: "auto",
          top: 80,
        },
      },
    },
    "& .product-meta-wrapper": {
      position: "absolute",
      bottom: 20,
      left: 10,
      display: "block",
      [theme.breakpoints.up("md")]: {
        bottom: "auto",
        left: 0,
        top: 230,
        textAlign: "center",
      },
    },
    "& *": {
      position: "relative",
      zIndex: 1,
    },
    "& .product-options": {
      display: "block",
      "& label": {
        display: "block",
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.palette.background.default,
        lineHeight: 1,
        fontFamily: theme.headerFont,
        fontWeight: "bold",
        "& select, .variant": {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.secondary.main + "99",
          lineHeight: 2,
          padding: "18px 12px",
          fontSize: 12,
          borderRadius: 4,
          boxShadow: "0 1px 3px rgba(0,0,0,0.24)",
          marginBottom: 16,
          marginTop: 8,
          width: "100%",
          border: "none",
          fontFamily: theme.bodyFont,
          fontStyle: "italic",
          display: "block",
          outline: "none !important",
          "& option": {
            fontStyle: "none",
          },
        },
      },
    },
    "& .quantity": {
      width: 90,
      display: "block",
      position: "relative",
      float: "left",
      marginRight: 16,
      marginBottom: 24,
      zIndex: 2,
      "& span": {
        display: "block",
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.palette.background.default,
        lineHeight: 1,
        fontFamily: theme.headerFont,
        fontWeight: "bold",
      },
      "& button": {
        position: "absolute",
        backgroundColor: "transparent",
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        textAlign: "center",
        fontSize: 18,
        lineHeight: "36px",
        padding: 0,
        fontWeight: "bold",
        border: "none",
        outline: "none !important",
        left: 0,
        bottom: 16,
        width: 30,
        height: 52,
        zIndex: 2,
        "&:nth-of-type(2)": {
          left: "auto",
          right: 0,
        },
      },
      "& input": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main + "99",
        lineHeight: 2,
        padding: "10px 0",
        fontSize: 16,
        borderRadius: 4,
        boxShadow: "0 1px 3px rgba(0,0,0,0.24)",
        marginBottom: 16,
        marginTop: 8,
        width: "100%",
        border: "none",
        fontFamily: theme.bodyFont,
        textAlign: "center",
        display: "block",
        outline: "none !important",
        borderLeft: "30px solid " + theme.palette.background.default,
        borderRight: "30px solid " + theme.palette.background.default,
      },
    },
    "& .tasting-notes": {
      boxSizing: "border-box",
      [theme.breakpoints.up("md")]: {
        width: 230,
        position: "absolute",
        right: 0,
        top: 130,
      },
      "&::after": {
        content: '""',
        display: "table",
        clear: "both",
      },
      "& h3": {
        color: theme.palette.background.default,
        textTransform: "uppercase",
        lineHeight: 1.25,
        fontSize: 24,
        marginBottom: 16,
        letterSpacing: "0.04em",
        "&::before": {
          content: '""',
          width: 32,
          borderTop: "4px solid " + theme.palette.primary.main,
          display: "block",
          marginBottom: 12,
        },
      },
      "& p": {
        color: theme.palette.background.default,
        fontSize: 16,
        lineHeight: 2,
        marginBottom: 32,
      },
      "& a.button": {
        width: "100%",
        borderRadius: 4,
        fontFamily: theme.headerFont,
        padding: "14px 22px",
        boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: 400,
        textAlign: "left",
        textTransform: "uppercase",
        border: "none",
        outline: "none !important",
        backgroundImage: "url(" + linkArrow + ")",
        backgroundSize: "18px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center right 22px",
        float: "right",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        letterSpacing: "0.06em",
        textDecoration: "none",
        transition: "background-color .3s ease-in-out",
        "&:hover, &:focus": {
          backgroundColor: "#72ce9b",
        },
      },
    },
  },
  otherProducts: {
    marginTop: 38,
    marginBottom: 40,
    position: "relative",
    "&::before": {
      position: "absolute",
      backgroundColor: theme.palette.common.white,
      top: -40,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: -25,
      content: '""',
      [theme.breakpoints.up(1330)]: {
        width: "calc(100vw - 100px)",
        transform: "translateX(-50%)",
      },
    },
    "& .slick-slider": {
      paddingBottom: 60,
      "& .slick-slide": {
        width: "230px !important",
        paddingRight: 24,
        "& .item-slide": {
          "& > div": {
            boxShadow: "none !important",
          },
          "& a": {
            borderTop: "none",
          },
          [theme.breakpoints.up("md")]: {
            "& div[style]": {
              borderBottomWidth: "18px !important",
            },
            "& h2": {
              fontSize: "18px !important",
              marginTop: "24px !important",
            },
            "& img": {
              maxWidth: "130px !important",
              marginBottom: "-20px !important",
            },
          },
        },
      },
      "& .slick-dots": {
        fontSize: 0,
        bottom: 10,
        "& li": {
          width: 12,
          height: 12,
          margin: "0 12px",
          "& button": {
            width: 12,
            height: 12,
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "50%",
            "&::before": {
              content: '""',
            },
          },
          "&.slick-active": {
            position: "relative",
            "& button": {
              backgroundColor: theme.palette.primary.main,
              "&::before": {
                border: "2px solid",
                borderColor: theme.palette.primary.main,
                borderRadius: "50%",
                top: -3,
                left: -3,
                width: 18,
                height: 18,
                backgroundColor: "transparent",
                position: "absolute",
              },
            },
          },
        },
      },
      "& .slick-next": {
        width: 18,
        height: 18,
        backgroundImage: "url(" + sliderArrow + ") !important",
        backgroundSize: "contain !important",
        backgroundPosition: "center !important",
        backgroundRepeat: "no-repeat !important",
        top: "auto",
        right: 25,
        bottom: 6,
        transform: "none",
        zIndex: 10,
        "&::before": {
          display: "none",
        },
        "&.slick-disabled": {
          opacity: 0.25,
        },
      },
      "& .slick-prev": {
        width: 18,
        height: 18,
        backgroundImage: "url(" + sliderArrow + ") !important",
        backgroundSize: "contain !important",
        backgroundPosition: "center !important",
        backgroundRepeat: "no-repeat !important",
        top: "auto",
        left: 25,
        bottom: 6,
        transform: "rotate(180deg)",
        zIndex: 10,
        "&::before": {
          display: "none",
        },
        "&.slick-disabled": {
          opacity: 0.25,
        },
      },
    },
  },
});

const Beer = (props) => {
  const { classes } = props;

  const data = props.data.wpBeer;
  const beer = data;

  return (
    <Layout
      meta={{
        metaDescription: beer.acfBeerFields.metaDescription,
      }}
      path={props.pageContext.pagePath}
      title={beer.acfBeerFields.metaTitle}
      themeOptions={data.themeOptions}
    >
      <div className={classes.productBanner}>
        <div
          className="bg"
          style={{ backgroundColor: beer.acfBeerFields.backgroundColor }}
        />
        <GoBack fallback="/our-beers" />
        <h1>{beer.title}</h1>
        <div className="slider-wrap">
          <div className="product-meta-wrapper">
            <ProductMeta beer={beer.acfBeerFields} />
          </div>

          <ProductImageSlider
            mainImage={beer.acfBeerFields.image.mediaItemUrl}
            product={beer.acfBeerFields}
          />
        </div>

        <div className="tasting-notes">
          <h3>Tasting Notes</h3>
          <p>{beer.acfBeerFields.metaDescription}</p>
          <a href="#tasting-notes" className="button">
            View Notes
          </a>
        </div>
      </div>

      <Grid container spacing={5} direction="row">
        <Grid item xs={12} md={8}>
          <div className="product-description">
            <a id="tasting-notes"></a> {/* eslint-disable-line */}
            <ProductDescription product={beer.acfBeerFields} />
            <ProductShare
              pagePath={props.pageContext.pagePath}
              product={beer}
            />
            {/*Desktop Deliver Info*/}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <BeerNotes product={beer.acfBeerFields} isBeer={true} />
          {/*Mobile Deliver Info*/}
        </Grid>
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query($databaseId: Int!) {
    wpBeer(databaseId: {eq: $databaseId}){
        acfBeerFields {
          alcoholVolume
          backgroundColor
          backgroundImage {
            mediaItemUrl
          }
          image {
            mediaItemUrl
          }
          sweetness
          taste
          bitterness
          beerType
          colorDescription
          color
          aroma
          description
          metaTitle
          metaDescription
          dietaryGuidelines
          healthInformationPdf
        }
        title
        databaseId
        slug
        date
      }
    }
`;

export default withStyles(styles)(Beer);
