import React from 'react';
import data from "../../../.brew-cache/data.json";

export default class GoBack extends React.Component {

    back(fallback){
        if(document.referrer.includes(data.url)) {
            window.history.back();
        } else {
            window.location.href = data.url + fallback;
        }
    }

    render() {

        return (
            <button className="go-back" onClick={() => this.back(this.props.fallback)}>Back</button>
        );
    }
}