import React, {Component} from "react";
import {withStyles} from "@material-ui/styles";
import sliderArrow from "../../../images/slider-arrow.png";
import Slider from "react-slick/lib";

const styles = theme => ({
    productImageSlider: {
        maxWidth: 320,
        margin: '0 auto',
        '& .slick-dots': {
            fontSize: 0,
            bottom: -22,
            '& li': {
                width: 12,
                height: 12,
                margin: '0 6px',
                '& button': {
                    width: 12,
                    height: 12,
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '50%',
                    '&::before': {
                        content: '""',
                    },
                },
                '&.slick-active': {
                    position: 'relative',
                    '& button': {
                        backgroundColor: theme.palette.primary.main,
                        '&::before': {
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: '50%',
                            top: -3,
                            left: -3,
                            width: 18,
                            height: 18,
                            backgroundColor: 'transparent',
                            position: 'absolute',
                        }
                    }
                }
            }
        },
        '& .slick-next': {
            width: 18,
            height: 18,
            backgroundImage: 'url(' + sliderArrow + ') !important',
            backgroundSize: 'contain !important',
            backgroundPosition: 'center !important',
            backgroundRepeat: 'no-repeat !important',
            top: 'auto',
            right: 12,
            bottom: -25,
            transform: 'none',
            zIndex: 10,
            '&::before': {
                display: 'none',
            },
            '&.slick-disabled': {
                opacity: 0.25,
            },
        },
        '& .slick-prev': {
            width: 18,
            height: 18,
            backgroundImage: 'url(' + sliderArrow + ') !important',
            backgroundSize: 'contain !important',
            backgroundPosition: 'center !important',
            backgroundRepeat: 'no-repeat !important',
            top: 'auto',
            left: 12,
            bottom: -25,
            transform: 'rotate(180deg)',
            zIndex: 10,
            '&::before': {
                display: 'none',
            },
            '&.slick-disabled': {
                opacity: 0.25,
            },
        },
        '& .slick-slide': {
            paddingLeft: 55,
            [theme.breakpoints.up('md')]: {
                paddingLeft: 0,
            }
        },
    },
});

class ProductImageSlider extends Component {

    render() {

        const { classes } = this.props;

        const settings = {
            dots: true,
            arrows: true,
            infinite: true,
            fade: true,
        }

        return <Slider {...settings} className={classes.productImageSlider}>
            <img src={this.props.mainImage}  alt=""/>
            {this.props.product.image2 != null &&
                <img src={this.props.product.image2} alt=""/>
            }
            {this.props.product.image3 != null &&
                <img src={this.props.product.image3} alt=""/>
            }
            {this.props.product.image4 != null &&
                <img src={this.props.product.image4} alt=""/>
            }
            {this.props.product.image5 != null &&
                <img src={this.props.product.image5} alt=""/>
            }
        </Slider>
    }
}

export default withStyles(styles)(ProductImageSlider);
