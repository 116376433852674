import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Link from "../../Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
let data = require("../../../../.brew-cache/data.json");

const styles = (theme) => ({
  productShare: {
    "& .title": {
      fontSize: 16,
      letterSpacing: "0.04em",
      lineHeight: 1.5,
      textTransform: "uppercase",
      fontWeight: "600",
      marginBottom: 20,
      fontFamily: theme.headerFont,
    },
    "& .link-list": {
      marginTop: 10,
      "& a": {
        marginRight: 40,
        color: theme.palette.primary.main,
        "& svg": {
          width: 26,
          height: 26,
        },
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
});

class ProductShare extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.productShare}>
        <p className="title">
          Share this {this.props.product.acfBeerFields.productType}
        </p>
        <p className="link-list">
          <Link
            to={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              data.url +
              this.props.pagePath
            }
          >
            <FacebookIcon />
          </Link>
          <Link
            to={
              "https://twitter.com/intent/tweet?text=" +
              this.props.product.title +
              "&url=" +
              data.url +
              this.props.pagePath
            }
          >
            <TwitterIcon />
          </Link>
          <Link
            to={
              "https://www.linkedin.com/shareArticle?mini=true&url=" +
              data.url +
              this.props.pagePath +
              "&title=" +
              this.props.product.title
            }
          >
            <LinkedInIcon />
          </Link>
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(ProductShare);
